$.fn.toggleSort = function (value) {
    var $this = $(this);
    var $icon = $(".fa", this);

    $icon.removeClass('fa-sort');

    if (value == 'desc') {
        $this.removeClass('sortable-asc');
        $icon.removeClass('fa-sort-asc');
        $this.addClass('sortable-desc');
        $icon.addClass('fa-sort-desc');

    } else {
        $this.removeClass('sortable-desc');
        $icon.removeClass('fa-sort-desc');
        $this.addClass('sortable-asc');
        $icon.addClass('fa-sort-asc');
    }
};

var formatValidationErrors = function (jsonResponse) {
    var errors = new Array();

    for (var error in jsonResponse) {

        if (typeof jsonResponse[error] == 'string') {
            errors.push(jsonResponse[error]);
            continue;
        }

        for (var message in jsonResponse[error]) {
            errors.push(jsonResponse[error][message]);
        }
    }

    return errors.join('<br>');
};

var initMenu = function () {
    $('#menu ul').children('.active').parent().show();

    $("li.active.collapse").show();

    $('#menu li a').click(function () {
            var href = $(this).attr('href');

            if (href != '#') {
                return true;
            }

            var checkElement = $(this).next();

            if ((checkElement.is('ul')) && (checkElement.is(':visible'))) {
                checkElement.slideUp('fast');
                return false;
            }
            if ((checkElement.is('ul')) && (!checkElement.is(':visible'))) {
                checkElement.slideDown('fast');
                return false;
            }
        }
    );

    $(".nav-more").on('click', function () {
        var $container = $(this).closest("ul");
        $(this).hide();
        console.log($container);
        $(".collapse", $container).slideDown(400);
    });

    $(".nav-less").on('click', function () {
        var $container = $(this).closest("ul");
        $(".collapse:not(.active)",$container).hide();
        $(".nav-more", $container).show();
    });
}

var buildSearchUrl = function (param, value, url, multiple, removeKey) {

    var currentUrl = (url != undefined) ? url : document.location.href;
    var queryString = currentUrl.substring(currentUrl.lastIndexOf("?") + 1);

    if (queryString == document.location.href) {
        queryString = "";
    }

    var paramfound = false;

    //replace existing param with new value
    var newQueryString = $.map(queryString.split("&"), function (pair) {
        var pairs = pair.split("=");

        if (pairs[0] === removeKey) {
            return '';
        }
        if (pairs[0] === param) {
            // we have value
            if (multiple && pairs[1] && value) {
                // same value exist
                if (pairs[1].indexOf(value) >= 0) {
                    if (pairs[1].indexOf(',' + value) >= 0) {
                        pairs[1] = pairs[1].replace(',' + value, '');
                    } else {
                        pairs[1] = pairs[1].replace(value + ',', '');
                        pairs[1] = pairs[1].replace(value, '');
                    }
                } else {
                    pairs[1]+= ',' + value;
                }
            } else {
                // first time
                pairs[1] = value;
            }
            paramfound = true;
            return pairs.join("=");
        } else {
            return pair;
        }
    }).join("&");

    //if aparam not found add to query
    if (!paramfound) {
        if (newQueryString == "") {
            newQueryString = param + "=" + value;
        } else {
            newQueryString = newQueryString + "&" + param + "=" + value;
        }
    }

    // remove "&" in the end
    if (newQueryString.slice(-1) === '&') {
        newQueryString = newQueryString.slice(0, -1)
    }

    return newQueryString;
}

var getParameterByName = function (name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

var initPreviewGenNotifications = function () {
    $(".share-block.collapse[data-url]").each(function (index, elem) {
        var $shareBlock = $(elem);
        var checkStatus = setInterval(function () {
            var url = $shareBlock.data('url');
            $.get(url, function (response) {
                if (response.status && response.status == 1) {
                    clearInterval(checkStatus);
                    $shareBlock.next(".generating-previews-block").hide();
                    $shareBlock.removeClass('collapse');
                    $("a.collapse", $shareBlock.parents('td').siblings('.b2c-shop-link')).removeClass('collapse');
                    $("a.collapse", $shareBlock.parents('tr').siblings('.b2c-shop-link')).removeClass('collapse');
                    $.notify({
                        icon: "glyphicon glyphicon-warning-sign",
                        title: "<strong>Success!</strong>",
                        message: response.message
                    }, {
                        type: 'success',
                        placement: {
                            from: "top",
                            align: "center"
                        }
                    });
                }
            })
        }, 2000);
    });
}

/**
 * Opens share settings modal
 *
 * @param $trigger
 * @param shareUrl
 * @returns {boolean}
 */
var triggerShareSettingsModal = function ($trigger) {
    var $modal = $("#share-settings-modal");
    var linkId = $trigger.data('link-id');
    var accountId = $trigger.data('account-id');
    var dataUrl = '/accounts/' + accountId + '/link-share-settings';

    $modal.on('hidden.bs.modal', function () {
        $('.modal-body', $modal).html(" ");
    })

    $.get(dataUrl, {
        id: linkId,
    }, function (html) {
        $('.modal-body', $modal).html(html);
        $modal.modal('show');
    });

    return true;
}

var initReportRange = function () {
    var createdFrom = getParameterByName("createdFrom");
    var createdTo = getParameterByName("createdTo");

    if (createdFrom && createdTo) {
        $('#reportrange span').html(moment(createdFrom, "DD-MM-YYYY").format('MMMM D, YYYY') + ' - ' + moment(createdTo, "DD-MM-YYYY").format('MMMM D, YYYY'));
    }

    function cb(start, end) {
        $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
    }

    var ranges = {};

    ranges[locale.datepicker.today] = [moment(), moment()];
    ranges[locale.datepicker.last_7_days] = [moment().subtract(6, 'days'), moment()];
    ranges[locale.datepicker.last_30_days] = [moment().subtract(29, 'days'), moment()];
    ranges[locale.datepicker.last_3_months] = [moment().subtract(3, 'month'), moment()];
    ranges[locale.datepicker.last_6_months] = [moment().subtract(6, 'month'), moment()];

    $('#reportrange').daterangepicker({
        autoUpdateInput: true,
        opens: "left",
        applyClass: "btn-primary",
        locale: {
            applyLabel: locale.datepicker.apply,
            cancelLabel: locale.datepicker.cancel,
            fromLabel: locale.datepicker.from,
            toLabel: locale.datepicker.to,
            customRangeLabel: locale.datepicker.custom,
            daysOfWeek: locale.datepicker.daysOfWeek,
            monthNames: locale.datepicker.monthNames,
        },
        ranges: ranges
    }, cb);

    $('#reportrange').on('apply.daterangepicker', function (ev, picker) {
        var createdFrom = picker.startDate.format('DD-MM-YYYY');
        var createdTo = picker.endDate.format('DD-MM-YYYY');
        window.location.href = '?' + buildSearchUrl('createdFrom', createdFrom, buildSearchUrl('createdTo', createdTo));
    });

    $('#reportrange').on('cancel.daterangepicker', function () {
        window.location.href = '?' + buildSearchUrl('createdFrom', '', buildSearchUrl('createdTo', ''));
    });
}

var modalMessage = function (message, type) {
    if (type == 'success') {
        var title = locale.success_modal_title;
    }
    if (type == 'error') {
        var title = locale.error_modal_title;
    }
    $("body").append(
        '<div class="modal fade" tabindex="-1" role="dialog" id="message-modal" data-backdrop="static">'
        + '<div class="modal-dialog modal-md">'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
        + '<h4 class="modal-title">' + title + '</h4>'
        + '</div>'
        + '<div class="modal-body">'
        + '<div class="row text-left">'
        + '<div class="col-xs-12" id="message-modal-message"></div>'
        + '</div>'
        + '</div>'
        + '<div class="modal-footer">'
        + '<button type="button" class="btn btn-default" data-dismiss="modal">' + locale.close + '</button>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>'
    );
    var $modal = $('#message-modal');

    $("#message-modal-message", $modal).html(message);

    if (type == 'success') {
        $(".modal-body", $modal).addClass('bg-success');
    }
    if (type == 'error') {
        $(".modal-body", $modal).addClass('bg-danger');
    }

    $modal.on('hidden.bs.modal', function (e) {
        $(e.currentTarget).remove();
    });

    $modal.modal('show');
}

var modalConfirm = function (message, okCallback, cancelCallback) {
    $("body").append(
        '<div class="modal fade" tabindex="-1" role="dialog" id="confirm-modal" data-backdrop="static">'
        + '<div class="modal-dialog modal-md">'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '<h4 class="modal-title"><i class="fa fa-exclamation-triangle fa-lg"></i> ' + locale.please_confirm + '!</h4>'
        + '</div>'
        + '<div class="modal-body">'
        + '<div class="row text-left">'
        + '<div class="col-xs-12 message"></div>'
        + '</div>'
        + '</div>'
        + '<div class="modal-footer">'
        + '<button type="button" class="btn btn-default" data-dismiss="modal" data-cancel>' + locale.datepicker.cancel + '</button>'
        + '<button type="button" class="btn btn-primary" data-ok>' + locale.confirm + '</button>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>'
    );

    var $modal = $('#confirm-modal');

    $(".message", $modal).html(message);

    $modal.on('hidden.bs.modal', function (e) {
        $(e.currentTarget).remove();
    });

    $("button[data-ok]", $modal).on('click', function (e) {
        e.preventDefault();
        okCallback();
    });

    $("button[data-cancel]", $modal).on('click', function (e) {
        e.preventDefault();
        cancelCallback();
    });

    $modal.modal('show');

    return false;
}

var initModalConfirms = function () {
    $('a[data-confirm]').on('click', function (e) {
        e.preventDefault();
        modalConfirm($(this).data('confirm'), function () {
            window.location.href = $(this).prop('href');
        }.bind(this));
    });

    $('*[data-confirm]','.form-delete').on('click', function (e) {
        e.preventDefault();

        modalConfirm($(this).data('confirm'), function () {
            $(this).parents('form').submit();
        }.bind(this));
    });
}

var removeCookie = function (sKey, sPath, sDomain) {
    document.cookie = encodeURIComponent(sKey) +
        "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" +
        (sDomain ? "; domain=" + sDomain : "") +
        (sPath ? "; path=" + sPath : "");
}

var initCopyToClipboard = function () {
    var clipboard = new Clipboard('.copy-to-clipboard');

    clipboard.on('error', function (e) {
        alert("Your browser does not support copy to clipboard");
    });

    clipboard.on('success', function (e) {
        $('.copy-to-clipboard').removeClass('text-success');
        e.trigger.classList.add('text-success');
        $(e.trigger).fadeTo(100, 0.1).fadeTo(200, 1.0);

        $(e.trigger).popover({
            content: 'Link copied to clipboard',
            template: '<div class="popover" role="tooltip"><div class="arrow"></div><div class="popover-content"></div></div>',
            trigger: 'manual',
            placement: 'bottom'
        });

        $(e.trigger).on('shown.bs.popover', function () {
            $(this).siblings('.popover').on('click', function () {
                $(e.trigger).popover('hide');
            })

            setTimeout(function () {
                $(e.trigger).popover('hide');
            }, 3000);
        })

        $(e.trigger).popover('show');

    });
}

var initSortLinks = function () {

    $("a.sortable", "th").on('click', function (e) {

        var $this = $(this);

        if ($this.hasClass('sortable-desc')) {
            var value = 'asc';
        }
        else {
            var value = 'desc';
        }

        window.location.href = '?' + buildSearchUrl('direction', value, buildSearchUrl('sort', $this.data('param')));

        return false;
    });

    var sortParamValue = getParameterByName('sort');

    $("a.sortable .fa", "table").addClass('fa-sort');

    if (sortParamValue) {
        var directionParamValue = getParameterByName('direction');

        if (!directionParamValue) {
            directionParamValue = 'desc';
        }

        $("a.sortable[data-param='" + sortParamValue + "']", "table").toggleSort(directionParamValue);
    }
}

var initSearchFileds = function () {
    $('.btn-search').on('click', function (e) {
        window.location.href = '?' + buildSearchUrl('search', $("#" + $(this).data('field-id')).val());
        return false;
    });

    $('.search-field').on('keypress', function (e) {
        if (e.which == 13) {
            window.location.href = '?' + buildSearchUrl('search', $(this).val());
        }
    });
}

var initHoverMenuDropdowns = function () {
    $(".dropdown-hover").hover(
        function () {
            $('.dropdown-menu', this).stop(true, true).fadeIn("fast");
            $(this).toggleClass('open');
        },
        function () {
            $('.dropdown-menu', this).stop(true, true).fadeOut("fast");
            $(this).toggleClass('open');
        });
}

var setPageTour = function (options) {

    if (options.backdrop === undefined) {
        options.backdrop = true;
    }

    //options.storage = false;
    options.debug = true;
    options.template =
        "<div class='popover tour'>" +
        "<div class='arrow'></div>" +
        "<h3 class='popover-title'></h3>" +
        "<div class='popover-content'></div>" +
        "<div class='popover-navigation'>" +
        "<button class='btn btn-default' data-role='prev'><i class='fa fa-angle-double-left'></i> Prev</button>" +
        "<span data-role='separator'> </span>" +
        "<button class='btn btn-default' data-role='next'>Next <i class='fa fa-angle-double-right'></i></button>" +
        "<button class='btn btn-default' data-role='end'><i class='fa fa-stop'></i> End</button>" +
        "</div>" +
        "</div>";

    var tour = new Tour(options);

    initTourButton(tour);

    /*if(!localStorage.getItem("welcome_tour_started")) {
     startTour(tour);
     localStorage.setItem("welcome_tour_started", "true");
     }*/

    tour.init();
    tour.start();

}

var initTourButton = function (tour) {
    $('#btn-tour-start').on('click', function (e) {
        tour.init();
        tour.restart();
    })
}

$(document).on('ready', function () {
    initMenu();
    initCopyToClipboard();
    initSortLinks();
    initSearchFileds();
    initModalConfirms();
    initHoverMenuDropdowns();

    $("#menu-toggle").click(function (e) {
        $("#wrapper").toggleClass("toggled");
        return false;
    });

    $("a.share-link").on("click", function () {
        triggerShareSettingsModal($(this));
        return false;
    });

    $(".link-input").on("click", function () {
        $(this).select();
        return false;
    });

    $('#success-modal').modal();
    $('#error-modal').modal();

    initPreviewGenNotifications();

    $('div.alert').not('.alert-important').delay(3000).fadeOut(350);

    // init tooltips
    $('[data-toggle="tooltip"]').tooltip();
});