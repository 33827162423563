/*
waitMe - 1.15 [15.02.16]
Author: vadimsva
Github: https://github.com/vadimsva/waitMe
*/
(function(b){b.fn.waitMe=function(p){return this.each(function(){var f=b(this),x,l,d,u=!1,t="background-color",q="",r="",v,a,w,k={init:function(){function y(a){m.css({top:"auto",transform:"translateY("+a+"px) translateZ(0)"})}a=b.extend({effect:"bounce",text:"",bg:"rgba(255,255,255,0.7)",color:"#000",maxSize:"",source:"",onClose:function(){}},p);w=(new Date).getMilliseconds();v=b('<div class="waitMe" data-waitme_id="'+w+'"></div>');switch(a.effect){case "none":d=0;break;case "bounce":d=3;break;case "rotateplane":d=
1;break;case "stretch":d=5;break;case "orbit":d=2;break;case "roundBounce":d=12;break;case "win8":d=5;u=!0;break;case "win8_linear":d=5;u=!0;break;case "ios":d=12;break;case "facebook":d=3;break;case "rotation":d=1;t="border-color";break;case "timer":d=2;var c=b.isArray(a.color)?a.color[0]:a.color;q="border-color:"+c;break;case "pulse":d=1;t="border-color";break;case "progressBar":d=1;break;case "bouncePulse":d=3;break;case "img":d=1}""!==q&&(q+=";");if(0<d){if("img"===a.effect)r='<img src="'+a.source+
'">';else for(var e=1;e<=d;++e)b.isArray(a.color)?(c=a.color[e],void 0==c&&(c="#000")):c=a.color,r=u?r+('<div class="waitMe_progress_elem'+e+'"><div style="'+t+":"+c+'"></div></div>'):r+('<div class="waitMe_progress_elem'+e+'" style="'+t+":"+c+'"></div>');l=b('<div class="waitMe_progress '+a.effect+'" style="'+q+'">'+r+"</div>")}a.text&&""===a.maxSize&&(c=b.isArray(a.color)?a.color[0]:a.color,x=b('<div class="waitMe_text" style="color:'+c+'">'+a.text+"</div>"));var h=f.find("> .waitMe");h&&h.remove();
c=b('<div class="waitMe_content"></div>');c.append(l,x);v.append(c);"HTML"==f[0].tagName&&(f=b("body"));f.addClass("waitMe_container").attr("data-waitme_id",w).append(v);var h=f.find("> .waitMe"),m=f.find(".waitMe_content");h.css({background:a.bg});m.css({marginTop:-m.outerHeight()/2+"px"});""!==a.maxSize&&(e=l.outerHeight(),l.outerWidth(),"img"===a.effect?(l.css({height:a.maxSize+"px"}),l.find(">img").css({maxHeight:"100%"}),m.css({marginTop:-m.outerHeight()/2+"px"})):a.maxSize<e&&c.css({transform:"scale("+
a.maxSize/e+")"}));if(f.outerHeight()>b(window).height()){var c=b(window).scrollTop(),g=m.outerHeight(),n=f.offset().top,k=f.outerHeight(),e=c-n+b(window).height()/2;0>e&&(e=Math.abs(e));0<=e-g&&e+g<=k?n-c>b(window).height()/2&&(e=g):e=c>n+k-g?c-n-g:c-n+g;y(e);b(document).scroll(function(){var a=b(window).scrollTop()-n+b(window).height()/2;0<=a-g&&a+g<=k&&y(a)})}h.on("destroyed",function(){if(a.onClose&&b.isFunction(a.onClose))a.onClose();h.trigger("close")});b.event.special.destroyed={remove:function(a){a.handler&&
a.handler()}};return h},hide:function(){var a=f.attr("data-waitme_id");f.removeClass("waitMe_container").removeAttr("data-waitme_id");f.find('.waitMe[data-waitme_id="'+a+'"]').remove()}};if(k[p])return k[p].apply(this,Array.prototype.slice.call(arguments,1));if("object"===typeof p||!p)return k.init.apply(this,arguments)})};b(window).on("load",function(){b("body.waitMe_body").addClass("hideMe");setTimeout(function(){b("body.waitMe_body").find(".waitMe_container:not([data-waitme_id])").remove();b("body.waitMe_body").removeClass("waitMe_body hideMe")},
200)})})(jQuery);
